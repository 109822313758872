const URLs = {
  // 모달로 구현하거나, 아예 사용하지 않는 url이 생길 수 있음.

  Main: "/", // 메인
  SignIn: "/sign-in", // 로그인

  // 통합도서정보관리
  // 도서마스터
  BookSearch: "/book/master/search/", // 도서전체조회
  BookRegister: "/book/master/register/", // 도서신규등록
  BookModifyRequest: "/book/master/modify-request/", // 수정요청(출판사)
  BookRecommendedInstitutions: "/book/master/recommended-institutions/", // 추천기관, 수상
  BookRecommendedInstitutionsRegister:
    "/book/master/recommended-institutions-register/", // 추천기관/수상 도서 등록/수정  (DB에 없음)
  BookReviewManagement: "/book/master/review-management/", // 리뷰관리
  BookReviewForbiddenWord: "/book/master/review-forbidden-word/", // 리뷰 금지어 설정 (DB에 없음)
  // 저자마스터
  BookAuthorManagement: "/book/author/manage/", // 저자관리

  // 홈페이지관리
  // 게시판
  HomeNotice: "/home/board/notice/", // 공지사항
  HomeNoticeRegister: "/home/board/notice-register/", // 공지사항 등록/수정 (DB에 없음)
  HomeNews: "/home/board/news/", // 조합원소식
  HomeNewsRegister: "/home/board/news-register/", // 조합원소식 등록/수정 (DB에 없음)
  HomeReferenceLibrary: "/home/board/reference-library/", // 자료실
  HomeReferenceLibraryRegister: "/home/board/reference-library-register/", // 자료실 등록/수정 (DB에 없음)
  // 조합소개
  HomeExecutivesAndEmployees: "/home/introduction/executives-and-employees/", // 임직원
  HomeExecutivesAndEmployeesRegister:
    "/home/introduction/executives-and-employees-register/", // 임직원 등록/수정 (DB에 없음)
  HomeHistory: "/home/introduction/history/", // 연혁
  HomeHistoryRegister: "/home/introduction/history-register/", // 연혁 등록/수정 (DB에 없음)
  // 조합원안내
  HomeMember: "/home/member/manage/", // 조합원
  HomeMemberRegister: "/home/member/register/", // 조합원 등록/수정 (DB에 없음)
  HomeCooperativeMember: "/home/member/cooperative-member/", // 일원화회원
  HomeCooperativeMemberRegister: "/home/member/cooperative-member-Register/", // 일원화회원 등록/수정 (DB에 없음)
  // 공통설정
  HomeContacts: "/home/common/contacts/", // 연락처관리
  HomeContactsModify: "/home/common/contacts-modify/", // 연락처수정 (DB에 없음)
  HomeFiles: "/home/common/files/", // 파일관리
  HomeFilesUpload: "/home/common/files-upload/", // 파일 업로드 (DB에 없음)

  // 출판사전용공간
  // 공지사항관리
  PubNotice: "/pub/notice/manage/", // 공지사항관리
  PubNoticeRegister: "/pub/notice/register/", // 공지사항 등록/수정 (DB에 없음)
  // 보도자료관리
  PubPressRelease: "/pub/press-release/manage/", // 보도자료관리
  // 담당자정보
  PubManagerInfo: "/pub/manager-info/manage/", // 담당자정보

  // 서점전용공간
  // 공지사항
  StoreNotice: "/store/notice/manage/", // 공지사항
  StoreNoticeRegister: "/store/notice/register/", // 공지사항 등록/수정 (DB에 없음)
  // 반입관리
  StoreSearchReturnOut: "/store/return/search-return-out/", // 반출요청조회
  StoreSearchReturnIn: "/store/return/search-return-in/", // 반입요청조회
  StoreRegisterReturnIn: "/store/return/register-return-in/", // 반입요청등록
  // 1:1문의
  StoreOneToOne: "/store/one-to-one/manage/", // 1:1문의
  // 신간도서목록
  StoreNewBookList: "/store/new-book/list/", // 신간도서목록
  StoreNewBookListRegister: "/store/new-book/register/", // 신간도서 등록/수정 (DB에 없음)
  // 주문진행현황
  StoreSearchOrder: "/store/order/search-order/", // 주문
  StoreSearchForwarding: "/store/order/search-forwarding/", // 출고

  // 책광장모두
  // 노출설정
  ModooExposureMain: "/modoo/exposure/main/", // 메인
  ModooExposureCategory: "/modoo/exposure/category/", // 분야별도서
  ModooExposureRecommend: "/modoo/exposure/recommend/", // 추천도서
  // 기획전/이벤트
  ModooExhibition: "/modoo/events/exhibition/", // 기획전
  ModooExhibitionRegister: "/modoo/events/exhibition-register/", // 기획전 등록 (DB에 없음)
  ModooEvent: "/modoo/events/event/", // 이벤트
  ModooEventRegister: "/modoo/events/event-register/", // 이벤트 등록 (DB에 없음)
  // 입점몰관리
  ModooMallManagement: "/modoo/mall/manage/", // 입점몰관리
  // 문의관리
  ModooOneToOne: "/modoo/inquiry/one-to-one/", // 1:1문의
  ModooFAQ: "/modoo/inquiry/faq/", // FAQ
  // 공지사항관리
  ModooNotice: "/modoo/notice/manage/", // 공지사항관리
  ModooNoticeRegister: "/modoo/notice/register/", // 공지사항 등록/수정 (DB에 없음)
  // 정책관리
  ModooTerms: "/modoo/policy/terms/", // 약관
  ModooDelivery: "/modoo/policy/delivery/", // 배송비
  ModooSaveDiscountCalculate: "/modoo/policy/save-discount-calculate/", // 적립/할인/정산
  ModooCoupon: "/modoo/policy/coupon/", // 쿠폰
  // 주문관리
  ModooOrderStatus: "/modoo/order/order/", // 주문현황
  ModooOrderDetail: "/modoo/order/order-detail/", // 주문현황 상세 (DB에 없음)
  ModooCancelStatus: "/modoo/order/cancel/", // 취소현황
  ModooCancelDetail: "/modoo/order/cancel-detail/", // 취소현황 상세 (DB에 없음)
  ModooReturnStatus: "/modoo/order/return/", // 반품현황
  ModooReturnDetail: "/modoo/order/return-detail/", // 반품현황 상세 (DB에 없음)
  ModooExchangeStatus: "/modoo/order/exchange/", // 교환현황
  ModooExchangeDetail: "/modoo/order/exchange-detail/", // 교환현황 상세 (DB에 없음)
  // 매출현황
  ModooSales: "/modoo/sales/board/", // 매출현황 메인
  // 일별 매출 현황
  ModooDaySalesAll: "/modoo/sales/day-sales-all/", // 일별 매출 현황(전체) (DB에 없음)
  ModooDaySalesSeparation: "/modoo/sales/day-sales-separation/", // 일별 매출 현황(구분) (DB에 없음)
  ModooDaySalesClient: "/modoo/sales/day-sales-client/", // 일별 매출 현황(거래처) (DB에 없음)
  ModooDaySalesProduct: "/modoo/sales/day-sales-product/", // 일별 매출 현황(품목) (DB에 없음)
  ModooMallDaySettlement: "/modoo/sales/mall-day-settlement/", // 판매현황 입점몰 정산(일자별)
  ModooDaySalesOrgnz: "/modoo/sales/mall-day-orgnz/", // 승인회원 매출현황
  // ModooDaySalesOrderSeq: "/modoo/sales/day-sales-order-seq/", // 주문번호 일별매출현황 (DB에 없음)
  // 월별 매출 현황
  ModooMonthSalesAll: "/modoo/sales/month-sales-all/", // 월별 매출 현황(전체) (DB에 없음)
  ModooMonthSalesSeparation: "/modoo/sales/month-sales-separation/", // 월별 매출 현황(구분) (DB에 없음)
  ModooMonthSalesClient: "/modoo/sales/month-sales-client/", // 월별 매출 현황(거래처) (DB에 없음)
  ModooMallMonthSettlement: "/modoo/sales/mall-settlement/", // 판매현황 입점몰 정산(월별) (DB에 없음)
  ModooMonthSalesProfit: "/modoo/sales/month-sales-profit/", // 월별 판매이익 현황 (DB에 없음)
  ModooMonthFundSaving: "/modoo/sales/month-fund-saving/", // 월별 기금적립 현황 (DB에 없음)
  // 회원관리
  ModooMember: "/modoo/member/manage/", // 회원관리
  ModooMemberDetail: "/modoo/member/detail/", // 회원 상세정보 (DB에 없음)

  // 모두같이
  // 기본설정
  MallSetting: "/mall/setting/setting/", // 기본설정
  // 메인노출설정
  MallMain: "/mall/exposure/main/",
  // 커뮤니티관리
  MallStory: "/mall/community/story/", // 스토리
  MallStoryRegister: "/mall/community/story-register/", // 스토리등록
  MallChannel: "/mall/community/channel/", // 채널
  MallChannelRegister: "/mall/community/channel-register/", // 채널등록
  MallNews: "/mall/community/news/", // 새소식
  MallNewsRegister: "/mall/community/news-register/", // 새소식등록
  // 정산내역조회
  MallSearchBalance: "/mall/balance/search-balance/", // 정산내역조회
  // 마케팅자료관리
  MallMarketing: "/mall/marketing/manage/", // 마케팅자료관리
  MallMarketingRegister: "/mall/marketing/register/", // 마케팅자료 등록 (DB에 없음)
  // 회원관리
  MallMember: "/mall/member/manage/", // 회원관리
  MallMemberDetail: "/mall/member/detail/", // 회원 상세정보 (DB에 없음)
  // 인증관리
  MallLifeJoin: "/mall/lifeJoin/lifeJoin", // 생협인증
  MallLifeJoinDetail: "/mall/lifeJoin/lifeJoin/detail/", // 회원 상세정보 (DB에 없음)

  // 공통관리
  // 회사정보관리
  CommonCompanyInfo: "/common/company/info/", // 회사정보관리
  // 임직원정보관리
  CommonEmployee: "/common/employee/manage/", // 임직원관리
  CommonEmployeeRegister: "/common/employee/register/", // 임직원 등록/수정 (DB에 없음)
  CommonRegisterAuthority: "/common/employee/authority/", // 권한등록
  // 팝업관리
  CommonPopup: "/common/popup/manage/", // 팝업관리
  CommonPopupRegister: "/common/popup/register/", // 팝업 등록 (DB에 없음)
  // SCM 비밀번호 초기화
  CommonScmPasswordReset: "/common/scm-password/reset/", // SCM 비밀번호 초기화
} as const;

export default URLs;
